import { mq } from '../../../cssInJs'

export default {
  orderDetails: {
    textAlign: 'left',
    marginBottom: '3rem',
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  summary: {

  },

  fn: mq({
    minWidth: ['7rem', null, '10em'],
    margin: 0
  }),
  fv: {

  },

  orderItems: {},
  orderItem: {
    padding: '0.5rem 0',
    margin: '0.5rem 0',
    borderBottom: 'solid 1px #ccc'
  },
  total: {
    //marginLeft: 15,
    fontWeight: 'bold',
    color: 'chocolate'
  }
}
