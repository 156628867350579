import { fb, validators } from '../../../lib/form';
export function getEwayFormModel(data) {
  const model = fb.group({
    cardHolderName: [data.cardHolderName || '', [validators.Required()], { label: 'Card Holder Name', type: 'text' }],
    cardNumber: [data.cardNumber || '', [validators.Required()], { label: 'Card Number', type: 'number' }],
    cardExpiryMM: [data.cardExpiryMM || '', [validators.Required()], { label: 'MM', type: 'number' }],
    cardExpiryYY: [data.cardExpiryMM || '', [validators.Required()], { label: 'YY', type: 'number' }],
    securityCode: [data.securityCode || '', [validators.Required()], { label: 'CVC', type: 'number' }]
  }); 
  return model;
}