/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { useEffect, useState, Fragment } from 'react';
import utils from '../../utils';
//import cmsUtils from '../../cms/utils/cmsUtils';
import env from '../../env';
//import { ErrorPopup, Loading, useGet } from '../../components';
import { useGet } from '../../components';

function SetupDisplayDate(date){
    let d = new Date(date);
    let _date = d.getDate();
    //let _month = d.getMonth();
    let _day = d.getDay();
    let _supText = "";

    switch(d.getDay()){
        case 0 :
            _day = "Sunday";
            break;
        case 1 :
            _day = "Monday";
            break;
        case 2 :
            _day = "Tuesday";
            break;
        case 3 :
            _day = "Wednesday";
            break;
        case 4 :
            _day = "Thursday";
            break;
        case 5 :
            _day = "Friday";
            break;
        case 6 :
            _day = "Saturday";
            break;
        default:
            break;
    }

    switch(_date){
        case 1: case 21: case 31:
            _supText = "st";
            break;
        case 2: case 22:
            _supText = "nd";
            break;
        case 3: case 23:
            _supText = "rd";
            break;
        default :
            _supText = "th";
            break;
    }
    return {date : _date, day : _day, supText : _supText};
}


function Recents (props){

    const recentDayList = props.recentDayList || [];
    const recentDayItems = recentDayList.map((item, index) => { 

        const displayDate = SetupDisplayDate(item.date);

        return(
            <div className="recent__day__list__item" key={index} css={style.recent__day__list__item}>
                <p css={style.recent__day__list__item__date}>{displayDate.day + ' ' + displayDate.date} <sup>{displayDate.supText}</sup></p>
                <div css={style.recent__day__list__item__count}>{item.sharkCount}</div>
            </div>
        )
    });

    return(
        <div className = "recent__day__list__container" css={style.recent__day__list__container}>
            {recentDayItems}
        </div>
    )
}

function RecentList (props){ 
    const sightingList = props.sightingList || [];
    //console.log("ddfsfs", sightingList);
    const sightingListItems = sightingList.map((item, index) => {
        const displayDate = SetupDisplayDate(item.date);

        let displayStatusText = "";
        switch(item.charterStatus + ''){
            case '0':
                displayStatusText = "Sighting";

                if(item.sharkCount === 0){
                    displayStatusText = "No Shark Sighting";
                }
                
                break;
            case '1':
                displayStatusText = "Non Activity Day";
                break;
            case '2':
                displayStatusText = "Cancelled";
                break;
            case '3':
                displayStatusText = "Cancelled-Weather";
                break;
            case '4':
                displayStatusText = "No Charter";
                break;
            default:
                break;
        }

        return(
            <div className="sighting__list__item" css={style.sighting__list__item} key={index}>
                <p className="sighting__list__item__date" css={style.sighting__list__item__date}>
                    {displayDate.day + ' ' + displayDate.date} <sup>{displayDate.supText}</sup>
                </p>
                <div className="sighting__list__item__status" css={style.sighting__list__item__status}>
                    {
                        (displayStatusText === 'Sighting' && item.sharkCount > 0) ?
                        <Fragment>
                            {
                                [...Array(item.sharkCount)].map((item, index) =>
                                <img css={style.shark__image} alt={'image' + index} key = {index} src="/assets/icons/shark-sighting.png"  style={{marginRight: '0.5rem'}}/> )
                            }
                        </Fragment> : displayStatusText
                    }
                </div>
            </div>
       )
    });
    
    return(
        <div className = "sighting__list" css={style.sighting__list}>
            {sightingListItems}
        </div>
    )
}


export function SightingView (props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'sighting_view', item.cssClass || item.anchorName || '');
    const get = useGet();
    const [sighting, setSighting] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        get.send(env.apiBase + "/api/sighting/getsightings");
        setLoaded(false);
        // eslint-disable-next-line
    }, []);

    // if (get.loading()) {
    //     return <Loading />
    // } else if (get.hasErrors()) {
    //     return <ErrorPopup title="Loading Error" errors={get.errors} />
    // }
    const data = get.response;
    
    if (get.done() && !loaded) {
        setLoaded(true);
        let _recentDayList = [];
        let _sightingList = [];
        if(data.length > 0){
            for(var i in data){
                if(data[i].charterStatus === 0 && _recentDayList.length < 3){
                    _recentDayList.push(data[i]);
                }

                if(_sightingList.length < 8){
                    _sightingList.push(data[i]);
                }
                
                if(_sightingList.length === 3 && _recentDayList.length === 8){
                    break;
                }   
            }
        }
        setSighting({recentDayList : _recentDayList, sightingList : _sightingList});
    }

    return(
        <div css = {style.sighting__view} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                sighting &&
                <Fragment>
                    <Recents recentDayList = {sighting.recentDayList}></Recents>
                    <RecentList sightingList = {sighting.sightingList}></RecentList>
                </Fragment>
            }
        </div>
    )
}