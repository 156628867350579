/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './payStyle';
import React from 'react';

import { SiteLink, usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useRouter } from '../../../components';
//import { initStripe } from './payStripe';
import { CartSummary } from '../cartSummary/summary';
import { getEwayFormModel } from './ewayFormModel';
import { useForm } from '../../../form';
import { gtm } from '../../../lib/tracking';
import { useStore } from '../../../store';

export function Pay({ cart, model, form, payStripe }) {

  //const [stripe, setStripe] = React.useState(null);
  //const [card, setCard] = React.useState(null);
  //const [error, setError] = React.useState(null);

  const post = usePost();
  const { Redirect } = useRouter();
  const { dispatch } = useStore();

  const ewayFormModel = getEwayFormModel({});
  const ewayForm = useForm(ewayFormModel, {usePlaceholder:true});
  const ewayFormRender = (name) => ewayForm.renderControl(name, null);

  React.useEffect(() => {
    //const { stripe, card } = initStripe(setError)
    //setStripe(stripe);
    //setCard(card);
  }, [])

  function payNow(e) {
    
    // if(window.eCrypt){
    //   console.log("12");
    // }
    ewayForm.validateForm(e, ()=>{
      
      if(window.eCrypt){
        const value = ewayForm.getValue();
        //console.log('aa', value, env.ewayClientKey);
        const payment = {
          cartId : env.getDevCartId(),
          itemTotalAmount : cart.itemTotalAmount,
          cardName : value.cardHolderName,
          cardNumber : window.eCrypt.encryptValue(value.cardNumber, env.ewayClientKey),
          cardExpMM : value.cardExpiryMM,
          cardExpYY : value.cardExpiryYY,
          cvv : window.eCrypt.encryptValue(value.securityCode, env.ewayClientKey),
        }
        //console.log('value', payment);
        onSubmit(payment);
      } 
    }); 
  }

  function onSubmit(payment) {
    //console.log(tokenId)
    post.send(env.apiBase + "/api/cart/payeway", payment);
  }

  if (post.done()) {
    env.setDevCartId(null);
    gtm.sendEvent('Confirm Payment');

    dispatch({type:'SET_STATE', payload : { cartInfo : 0 }});

    return <Redirect to={`/order-thankyou?o=${post.response.results.OrderIdToken}`} />
  }

  return <div css={style.pay}>
    <div className="row" >
      <div className="col-md-9">
        <h3>Pay by Credit Card</h3>

        <div css={style.stripeWrapper}>
          <div className="stripe-wrapper">
            {/* <div id="card-element"></div> */}
            <div className="row">
              <div className="col-md-6 col-12">
                {ewayFormRender('cardHolderName')}
              </div>
              <div className="col-md-6 col-12">
                {ewayFormRender('cardNumber')}
              </div>
              {/* <div className = "col-md-3">
                Card Expiry:
              </div> */}
              <div className = "col-12">
                Card Expiry
              </div>
              <div className = "col-md-4">
                {ewayFormRender('cardExpiryMM')}
              </div>
              <div className = "col-md-4">
                {ewayFormRender('cardExpiryYY')}
              </div>
              <div className = "col-12">
                Security Code
              </div>
              <div className = "col-md-4">
                {ewayFormRender('securityCode')}
              </div>
            </div>
            
          </div>
          <div css = {style.paymentInfoText}>
            Payment through encrypted gateway can be made by Visa, Mastercard or AMEX.  Please enter your credit card details to complete your order. 
            For alternative payment methods please <SiteLink to = "#contactForm" className="contact-link"> contact us </SiteLink>
          </div>
        </div>

      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>
    </div>
    <div css={style.formActions}>
      <Button onClick={payNow} status={post.status}>Pay Now</Button>
      <ErrorMessage errors={ewayForm.errors} summaryMessage="Please review the errors." />
      <ErrorMessage errors={post.errors} />
    </div>
  </div>
}