/** @jsx jsx */
import { jsx } from '@emotion/core';
import { getBlogTileStyle } from './blogTileStyle';
import utils from '../../utils';
import { SiteLink } from '../../components';
import { MdAccessTime, MdTurnedInNot } from "react-icons/md";

export function BlogTile({ page, useSlider, disableLink }) {
  const tileLink = page.pageUrl;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const title = page.pageTitle;
  const shortDesc = page.description;
  const categoryName = page.categoryName1;
  const postDate = utils.date.formatDDMMYYYY(page.postDate);

  const s = getBlogTileStyle();
  return (
    <SiteLink css={[s.tile, useSlider && s.tileSlider]} to={disableLink ? '' : tileLink} >
      <div css={s.tileBg} className="tileBg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
      <div css={s.tileContent} className="tileContent">
        <div css={s.title}>{title}</div>
        <div css={s.info}>
          <span css={s.postDate}><MdAccessTime /> {postDate}</span>
          {categoryName && <span css={s.category}><MdTurnedInNot /> {categoryName}</span>}
        </div>
        <div css={s.desc}>{shortDesc}</div>
      </div>
    </SiteLink>
  )
}