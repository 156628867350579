import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
//import { SiteLink } from '../../../components';

import { TileList } from '../../../site/tileList/tileList'
import { getTileListStyle } from '../../../site/tileList/tileListStyle';
import { BlogTile } from '../../../site/blogTile/blogTile';

export function BlogPosts(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const posts = getPages(cmsUtils.payload(item, 'BlogPosts'));
  const numOfTileLines = parseInt(cmsUtils.payload(item, 'NumOfTileLines', '2'));
  //console.log(numOfPost, parseInt(numOfPost));
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = {xs:1, sm:1, md:2, lg:2, xl:3, xxl:3}
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 3);
  const step = numOfPost;
  console.log(bp, numOfTileLines, tilesPerLine, numOfPost)

  const [tilesToShow, setTilesToShow] = React.useState(numOfPost);

  const displayingPosts = posts.length > tilesToShow ? posts.slice(0, tilesToShow) : posts;
  const showMore = posts.length > displayingPosts.length;

  const tiles = displayingPosts.map((page, index) =>
    <BlogTile page={page} key={index} disableLink={cmsOption.isCmsEdit} />
  );

  const cssClass = utils.classNames('cms_item', 'blogTiles__ tiles__', item.cssClass || item.anchorName || '');

  return (
    <>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        <TileList tiles={tiles} style={getTileListStyle()}/>
      </div>
      {showMore && (<div className="blogTiles__loadMore">
        <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + step) }}>LOAD MORE</span></div>)}
    </>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}
