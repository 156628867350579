import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

const numberOfTiles = [1, 2, 3, 3]
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const widthPercent = (index) => 100 / numberOfTiles[index] + '%'

const linkText = {
  color: variables.fontColor,
  fontSize: '0.8rem',
  //padding: '0 20px',
  //paddingLeft: '1rem',
  //paddingRight: '1rem',
  // '&:hover': {
  //   //color: variables.fontColor,
  // }
}

export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth:['960px'],
    margin: 'auto'
  }),
  categoryList : mq({
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth:['960px'],
    margin: 'auto',
    'div.ff.form-group' : {
      maxWidth: '250px'
    }
  })
}

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingLeft : '10px',
    paddingRight : '10px',
    paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexGrow: 0,
    flexShrink: 0
  }),
  tileLink: {
    //background: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    //padding: 5,
    //border: '1px solid red',
    '&:hover': {
      textDecoration: 'none',
      //background: '#f3f3f3'
      '.bg__ground__image' : {
        transform: 'scale(1.02)'
      },
      '.buy__now_btn' : {
        color: 'white',
        backgroundColor:variables.lightBlue
      }
    }
  },

  tileContent: mq({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor:'white',
    marginTop: '0.5rem',
    padding: '0.5rem 0.8rem',
    //flex: ['1 0 150px', null,'1 0 215px']
    flex: ['1 0 150px', null,'1 0 208px']
    
    //boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
    //backgroundColor: '#fff',
    //marginTop: [10],
    //padding: [20],
    //transition: 'all .2s ease-in-out'
  }),

  prdImage: {
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    //width: '100%',
    backgroundColor: 'grey'
  },
  bg: mq({
    //paddingTop:['160px', null, '210px'],
    paddingTop:['160px', null, '170px'],
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'all .2s'
  }),

  // shortDes : css({
  //   //textAlign:'center',
  //   //marginTop: '0.8rem',
  //   //paddingLeft: '1rem',
  //   //paddingRight: '1rem',
  //   fontSize:'0.7rem',
  //   color:variables.fontColor,
  //   flex:'1 0 70px',
  //   overflow:'hidden',
  //   //marginBottom:'0.5rem',
  //   lineHeight:'1.3'
  // }),

  shortDes : mq({
    fontSize:'0.7rem',
    color:variables.fontColor,
    //flex:'1 0 70px',
    flex : ['1 0 50px', null, '1 0 70px'],
    overflow:'hidden',
    lineHeight:'1.3'
  }),

  name: css(linkText, {
    fontWeight: '900',
    fontFamily: variables.familyHeader,
    //textAlign:'center',
    marginBottom:'8px',
    //color : '#0c4da2',
    //fontSize:'1rem',
    fontSize:'1.238rem',
    lineHeight:'1',
    //marginTop:'0.5rem',
    color:variables.lightBlue
  }),
  price: css(linkText, {
    //marginTop: '1rem',
    //textAlign:'center',
    //marginBottom:'0.3rem',
    fontWeight: '700',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem'
    //paddingTop: '0.'
  }),
  buyNow : css(linkText,{
    border:'1px solid' + variables.lightBlue,
    textAlign:'center',
    //marginBottom:'0.5rem',
    //marginLeft: '1rem',
    //marginRight: '1rem',
    transition:'all .2s',
    marginTop: '0.3rem'
  })
}