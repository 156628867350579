import facepaint from 'facepaint'
//                  'sm', 'md', 'lg', 'xl', 'xxl'
const breakpoints = [576,  768,  992,  1200, 1600, 1900, 2200] 

export const mqBanner = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

//                                  576, 768, 992, 1200, 1600, 1900, 2200
export const bannerThin =     [180, 190, 200, 220, 250,  270,  290,  300]
export const bannerSmall =    [220, 260, 330, 400, 450,  530,  600,  700]
export const bannerStandard = [350, 350, 400, 450, 500,  550,  600,  700]

export const getBannerSize = (setName) => {
  if (setName === 'standard')
    return bannerStandard
  else if (setName === 'small')
    return bannerSmall
    else if (setName === 'thin')
    return bannerThin
  else
    return bannerStandard
}