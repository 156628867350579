/** @jsx jsx */
import { jsx } from '@emotion/core'
import Slider from "react-slick";
import { settings as defaultSettings} from './config';

/**
 * Tile List
 * @param {Array<JSX.Element>} tiles React elements, required
 * @param {Object} style Style object, required
 * @param {Object} settings Tile Settings, required
 * @param {string} cssClass
 * @param {Object} attrs Other attributes in the list level
 */
export function TileList({tiles, style, settings = defaultSettings, cssClass, attrs}) {
  const useSlider = false;
  const s = style || {};
  const slickSettings = settings.slickSettings;

  if (useSlider) {
    return (
      <div className={cssClass} css={s.holder} {...attrs}>
        <div css={[s.tiles, useSlider && s.tilesSlider]}>
          <Slider {...slickSettings} >
            {tiles}
          </Slider>
        </div>
      </div>
    );
  } else {
    return (
      <div className={cssClass} css={s.holder} {...attrs}>
        <div css={s.tiles}>
          {tiles}
        </div>
      </div>
    );
  }

}