/** @jsx jsx */
import { jsx } from '@emotion/core';
//import style from './style';
import { useEffect } from 'react';
//import utils from '../../utils';
//import cmsUtils from '../../cms/utils/cmsUtils';
//import env from '../../env';
//import { ErrorPopup, Loading, useGet } from '../../components';
//import { useGet } from '../../components';

export function BlogComment (props){
    useEffect(() => {
        //const abortController = new AbortController();
        //const signal = abortController.signal;
        const script = document.createElement('script');
        script.src = 'https://sharkcagediving-com-au.disqus.com/embed.js'; //Test url : https://test-o5lneyegil.disqus.com/embed.js
        script.setAttribute('data-timestamp', +new Date());
        document.body.appendChild(script);

        return function cleanup(){
            //abortController.abort();
            document.body.removeChild(script);
        }
    }, []);
    return(
       <div style={{maxWidth:'960px', margin:'auto'}}>
           <div id="disqus_thread"></div>
           <noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript>
       </div>  
    )
}