import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: '#1f4f9c',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [80, null, 120],
    marginTop:[86, null, 120]
  })),
  logoScrolled: css(mq({

  })),
  cartIconC : mq({
    
    position: 'fixed',
    right: ['34px', null, '100px'],

    '.container' : {
      position: 'relative',
    },

    '.count' : mq({
      position: 'absolute',
      backgroundColor: 'red',
      color: 'white',
      borderRadius: '20px',
      padding: '0rem 0.2rem',
      lineHeight: '1',
      left : ['2.5rem', null, '2rem']
    }),
    
    'svg' : mq({
      width : ['40px'],
      height : ['40px'],
      color: 'white',
    })

  })
}