import { variables, mq } from '../../cssInJs';
import { settings as defaultSettings} from './config';
/**
 * Tile List Style. This is only for list wrapper, not tile itself
 * @param {Object} settings Tile Settings, required
 */
export function getTileListStyle(settings = defaultSettings) {
  const marginLR = settings.marginLR;
  const marginTB = settings.marginTB;
  return {
    holder: mq({
      //maxWidth: 1000,
      margin: 'auto',
      marginTop: [30, null, 40],
      marginBottom: [30, null, 40],
      '.slick-prev, .slick-next': {
        top: '70px',
        transform: 'translate(0, 0)',
      },
      '.slick-prev': {
        left: [10, null, -20]
      },
      '.slick-next': {
        right: [10, null, -20]
      },
      '.slick-prev:before, .slick-next:before': {
        color: variables.fontColor
      }
    }),
    tiles: mq({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      marginLeft: marginLR.map(x => x === null? null : -x / 2),
      marginRight: marginLR.map(x => x === null? null : -x / 2),
      marginTop: marginTB.map(x => x === null? null : x / 2),
      marginBottom: marginTB.map(x => x === null? null : x / 2),
    }),
    tilesSlider: mq({
      display: 'block',
    })
  }
}

