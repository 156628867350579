import { css } from '@emotion/core'
import { mq } from '../../cssInJs';

export default{
    
    sighting__view : css({
        maxWidth:'960px',
        margin:'auto',
        marginTop: '1rem',
        marginBottom:'1rem',
        p : css({
            marginBottom: '0px'
        })
    }), 
    recent__day__list__container : css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    }),
    recent__day__list__item : css(mq({
        flexGrow: '1',
        flexShrink: '1',
        flexBasis: ['33.3%'],
        maxWidth: ['33.3%'],
        padding:'0.5rem',
        textAlign : 'center'
    })),


    // recent__day__list__item__date : css({
    //     fontWeight:'700',
    //     color:'#00aeef',
    //     fontSize:'1.4rem'
    // }),

    recent__day__list__item__date : mq({
        fontWeight:'700',
        color:'#00aeef',
        fontSize:['0.8rem', null, '1.4rem']
    }),

    recent__day__list__item__count : css({
        fontWeight:'600',
        fontSize:'3rem',
        color:'white'
    }),
    sighting__list : css({
        marginTop: '1rem'
    }),
    sighting__list__item : css(mq({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        paddingTop: '1px',
        paddingBottom:'1px',
        overflow: 'hidden'
    })),

    sighting__list__item__date : css(mq({
        flex: ['0 1 100%', '0 1 25%'],
        backgroundColor: '#00aeef',
        color:'white',
        padding:'0.1rem 1rem'
    })),

    sighting__list__item__status : css({
        flex: ['1 0 auto'],
        backgroundColor: 'white',
        padding:'0.1rem 1rem'
    }),
    shark__image : mq({
        maxWidth:['8% !important', '100% !important']
    })
}