function payload(item, key, defaultVal = '') {
  const found = item.payloads.find(x => x.key === key);
  return found ? found.value : defaultVal;
}

function getCodeJson(item) {
  const code = payload(item, 'Code');
  const codeType = payload(item, 'CodeType');
  if (codeType === 'Json') {
    const codeJson = JSON.parse(code);
    return codeJson
  }
  return null
}

export default { payload, getCodeJson };