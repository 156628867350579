import React, { useState, useEffect } from 'react';
import { useGet } from '../../../components';
import { Item } from '../../items/item';
import { NotFound } from '../../../components';
import env from '../../../env';
//import { useStore } from '../../../store';
import { ErrorPopup, Loading } from '../../../components';

const log = (...args) => {
  //console.log(args);
}
const areEqual = (prevProps, nextProps) => {
  return (prevProps.pageData === nextProps.pageData)
};
const PageMemo = React.memo(Page, areEqual)

export function ProductPagePartial({pageOptions = null}) {
  //const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  //const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const pathname = '/partials/common-product-page-footer';
  const get = useGet();
  log('0. viewpage ready');

  useEffect(() => {
    log('*. start loading')
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  //log(router.pathname);
  //log(get.loading());
  log('1. viewpage component', pageLoaded, get.response);


  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const cmsOption = { pageOptions: pageOptions};
  log(pageLoaded, !pageLoaded && !!pageData)
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log('2. dispatching page & menu')
    setPageLoaded(true);
    //document.title = pageData.pageTitle;
    return null;
  } else if (!pageData) {
    return (<NotFound />)
  }

  log('3. call rendering memo')
  return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
}


function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  // document.title = pageData.pageTitle;
  log('4. page rendering', pageData, cmsOption)
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>
    )
  }
  );
  return (
    <>
      {sectionItems}
    </>
  );
}

function Section(props) {
  log('5. section rendering')
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0)
    return (<></>);

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <>
      {subItemsElts}
    </>
  );
}

