import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import { Item } from '../../items/item';
import  utils from '../../../utils';

export function Panel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);
  
  const bgColor = cmsUtils.payload(item, 'BgColor');
  const waveColor = cmsUtils.payload(item, 'BgColor');
  const wave = cmsUtils.payload(item, 'Wave') || 'none';
  //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
  const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
  const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
  //console.log(bgTheme)

  let bgUrl = null;
  if (imageUrl)
    bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

  const cssClass = utils.classNames('cms_item', 'contentPanel', 'contentPanel--' + bgTheme, item.cssClass || item.anchorName || '');

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  return (
    <section className={cssClass} style={{
      backgroundColor: `${bgColor}`,
      backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
      }} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="contentPanel__bg"></div>
      
      {
        (wave === 'top' || wave === 'both') &&
        <div className="wave-animation-top" style={{color:waveColor}}>
          <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 25 H 0 V 0"></path></g></svg>
        </div>
      }

      <div className="container">
        {subItemsElts}
      </div>

      {
        (wave === 'bottom' || wave === 'both') &&
        <div className="wave-animation-bottom" style={{color:waveColor}}>
          <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5"></path></g></svg>
        </div>
      }

    </section>
  );
}