import { css  } from '@emotion/core'
import { mq } from '../../../cssInJs'

const navs = {
  navs: css(mq({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 'solid 1px #ccc',
    marginBottom: 8,
    background: 'rgba(255,255,255,0.9)',
    //background: 'linear-gradient(0deg, rgba(153,153,153,1) 0%, rgba(255,255,255,1) 100%)',
  })),
  nav: css(mq({
    //width: 20,
    //height: 30,
    padding: '0.9rem 1rem',
    fontSize: '1.1rem',
    position: 'relative',
    flex: '1 1 auto',
    textAlign: 'center',
  })),
  navArrow: {
  },
  navCurrent: css({
    //color: 'white',
    //backgroundColor: variables.primaryColor
    //background: 'linear-gradient(0deg, rgba(34,45,100,1) 0%, rgba(89,102,166,1) 100%)',
    //borderBottom: 'solid 2px ' + variables.primaryColor
    //background: '#ddd',
    fontWeight: 'bold',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.08) 0%, rgba(255,255,255,1) 100%)',
    //borderBottom: 'solid 2px ' + variables.primaryColor
  }),
  navFilled: css({
    //backgroundColor: '#666'
  }),
  navClickable: css({
    cursor: 'pointer'
  })
}

export default {

  arrow: css(mq({
    position: "absolute",
    cursor: 'pointer',
    color: '#aaa',
    transition: 'all 0.3s',
    zIndex: '1',
    // xs, sm(>=576), md(>=768), lg(>=992), xl(>=1200), xxl(>=1600)
    top: 0,
    // [mqMax.md]: {
    //   width: 100
    // },
    width: [20, 25, 30, 40],
    '&:hover': {
      color: '#666'
    }
  })),
  arrowPrev: css(mq({
    left: [-15, 0]
  })),
  arrowNext: css(mq({
    right: [-15, 0]
  })),

  ...navs
}
